import {
  CartIcon,
  HandIcon,
  ImageIcon,
  MinusIcon,
  RefreshIcon,
  TextIcon,
  TicketIcon,
  UserIcon,
} from "../../Icons";
import { DraggableIconCard } from "../../elements/_Elements";

function ControlElements() {
  return (
    <>
      <DraggableIconCard
        id="text-control"
        icon={TextIcon}
        title="Text"
      />
      <DraggableIconCard
        id="image-control"
        icon={ImageIcon}
        title="Image"
      />
      <DraggableIconCard
        id="image-text-control"
        icon={ImageIcon}
        title="Text & Image"
      />
      <DraggableIconCard
        id="divider-control"
        icon={MinusIcon}
        title="Divider"
      />
      <DraggableIconCard
        id="button-control"
        icon={HandIcon}
        title="Button"
      />
      <DraggableIconCard
        id="social-follow-control"
        icon={UserIcon}
        title="Social Follow"
      />
      <DraggableIconCard
        id="booking-control"
        icon={CartIcon}
        title="Booking"
      />
      <DraggableIconCard
        id="coupon-control"
        icon={TicketIcon}
        title="Coupon"
      />
      <DraggableIconCard
        id="voucher-control"
        icon={TicketIcon}
        title="Voucher"
      />
      <DraggableIconCard
        id="membership-renewal-control"
        icon={RefreshIcon}
        title="Membership Renewal"
      />
    </>
  );
}

export default ControlElements;
