import axios from "axios";
import { GlobalDispatchMethods } from "./enums";
import lookups from "../forms/lookups";

const API = process.env.REACT_APP_API || "api";

export function Get(dispatch, method) {
  return axios
    .get(API + "/" + method)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);

      if (dispatch) {
        dispatch({
          type: GlobalDispatchMethods.AddNotification,
          notification: { message: error.message, success: false },
          dispatch: dispatch,
        });
      }

      return error;
    });
}

function Post(dispatch, method, successMessage, data, methodUrl) {
  return axios
    .post(API + "/" + method, data, {
      params: {
        methodUrl: methodUrl,
      },
    })
    .then(function (response) {
      if (successMessage && dispatch) {
        dispatch({
          type: GlobalDispatchMethods.AddNotification,
          notification: { message: successMessage, success: true },
          dispatch: dispatch,
        });
      }

      return response;
    })
    .catch(function (error) {
      console.log(error);

      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;

      if (dispatch) {
        dispatch({
          type: GlobalDispatchMethods.AddNotification,
          notification: { message: message, success: false },
          dispatch: dispatch,
        });
      }

      return error;
    });
}

export function CountCommunicationAudienceMembers(dispatch, request) {
  return Post(
    dispatch,
    "CountCommunicationAudienceMembers",
    "",
    request
  );
}

export function ExportCommunicationAudienceToFile(
  dispatch,
  successMessage,
  communicationAudience
) {
  return Post(
    dispatch,
    "ExportCommunicationAudienceToFile",
    successMessage,
    communicationAudience
  );
}

export function ExportCommunicationAudienceToMarketingList(
  dispatch,
  successMessage,
  communicationAudience
) {
  return Post(
    dispatch,
    "ExportCommunicationAudienceToMarketingList",
    successMessage,
    communicationAudience
  );
}

export function GetAdminSettings(dispatch) {
  return Get(dispatch, `GetAdminSettings`);
}

export function GetAllEntityBasicInfo(
  dispatch,
  entityName,
  searchFilter
) {
  let url = `GetAllEntityBasicInfo?entityname=${entityName}`;

  if (searchFilter && searchFilter !== "") {
    // Append the searchfilter parameter to the URL
    url += `&searchfilter=${searchFilter}`;
  }

  return Get(dispatch, url);
}
export function GetAllEntityDetailedInfo(
  dispatch,
  entityName,
  showInactiveRecords
) {
  let url = `GetAllEntityDetailedInfo?entityname=${entityName}&getinactiverecords=${showInactiveRecords}`;
  return Get(dispatch, url);
}

export function GetDefaultValuesForEntity(dispatch, entityName) {
  let url = `GetDefaultValuesForEntity?entityname=${entityName}`;
  return Get(dispatch, url);
}

export function GetEntityAttributes(dispatch, entityName) {
  let url = `GetEntityAttributes?entityname=${entityName}`;
  return Get(dispatch, url);
}

export function GetEntityAudits(dispatch, label, id) {
  let url = `GetEntityAudits?label=${label}&id=${id}`;
  return Get(dispatch, url);
}

export function GetBlacklistEntityAudits(dispatch) {
  return Get(dispatch, "GetBlacklistEntityAudits");
}

export function GetEntityAuditsByLoggingId(
  dispatch,
  label,
  loggingId
) {
  let url = `GetEntityAuditsByLoggingId?label=${label}&loggingid=${loggingId}`;
  return Get(dispatch, url);
}

export function GetEntityRecordById(dispatch, entityName, id) {
  let url = `GetEntityRecordById?entityname=${entityName}&id=${id}`;
  return Get(dispatch, url);
}

export function JournalDailyReport(dispatch, selectedDate) {
  let url = `JournalDailyReport?selecteddate=${selectedDate}`;
  return Get(dispatch, url);
}

export function ActivateCommunicationOptionsAccount(dispatch, code) {
  return Get(
    dispatch,
    `ActivateCommunicationOptionsAccount?activationCode=${code}`
  );
}

export function GetBookingCommActivity(dispatch, id) {
  let url = `GetBookingCommActivity?bookingId=${id}`;
  return Get(dispatch, url);
}

export function GetCommunicationAudienceRules(dispatch) {
  return Get(dispatch, `GetCommunicationAudienceRules`);
}

export function GetCommunicationOptions(dispatch) {
  return Get(dispatch, `GetCommunicationOptions`);
}

export function GetGlobalSettings(dispatch) {
  return Get(dispatch, `GetGlobalSettings`);
}

export function GetCommunicationStatistics(
  dispatch,
  communicationId,
  returnEmailHtml
) {
  let url = `GetCommunicationStatistics?communicationId=${communicationId}&returnEmailHtml=${returnEmailHtml}`;
  return Get(dispatch, url);
}

export function GetInitialState(dispatch) {
  return Get(dispatch, "GetInitialState");
}

export function GetPrintTransformLayouts(dispatch) {
  return Get(dispatch, `GetPrintTransformLayouts`);
}

export function GetReport(dispatch, reportType) {
  let url = `GetReport?type=${reportType}`;
  return Get(dispatch, url);
}

export function IsBlacklisted(dispatch, emailAddress) {
  return Get(dispatch, `IsBlacklisted?emailAddress=${emailAddress}`);
}

export function IsExcluded(dispatch, emailAddress) {
  return Get(dispatch, `IsExcluded?emailAddress=${emailAddress}`);
}

export function RemoveFromMailExclusion(dispatch, emailAddress) {
  return Get(
    dispatch,
    `RemoveFromMailExclusion?emailAddress=${emailAddress}`
  );
}

export function SendBookingConfirmation(dispatch, id) {
  let url = `SendBookingConfirmation?bookingId=${id}`;
  return Get(dispatch, url);
}

export function StartCommunication(dispatch, id) {
  return Get(dispatch, `StartCommunication?id=${id}`);
}

export function StopCommunication(dispatch, id) {
  return Get(dispatch, `StopCommunication?id=${id}`);
}

export function SendPaymentRequest(dispatch, id) {
  return Get(dispatch, `SendPaymentRequest?id=${id}`);
}

export function ProcessPaymentResponse(dispatch, id) {
  return Get(dispatch, `ProcessPaymentResponses?id=${id}`);
}

export function RetrievePaymentResponse(
  dispatch,
  successMessage,
  { PaymentBatchId, StartDate, EndDate, PaymentMethodId }
) {
  const method = "RetrievePaymentResponses";
  return Post(dispatch, method, successMessage, {
    PaymentBatchId,
    StartDate,
    EndDate,
    PaymentMethodId,
  });
}

export function UnpublishTriggerFlow(dispatch, id) {
  return Get(dispatch, `UnpublishTriggerFlow?id=${id}`);
}

export function Unsuppress(dispatch, emailAddress) {
  return Get(dispatch, `Unsuppress?emailAddress=${emailAddress}`);
}

export function Create(
  dispatch,
  successMessage,
  { Id, Name, Attributes },
  entityName,
  methodName
) {
  const method = methodName
    ? methodName
    : GetCreateMethod(entityName);
  ClearCache(entityName);
  // create or update the entity
  return Post(
    dispatch,
    "Create",
    successMessage,
    {
      Id,
      Name,
      Attributes,
    },
    method
  );
}

export function Search(
  dispatch,
  { PageRequested, PageSize, Filters, EntityName }
) {
  // search for records
  return Post(dispatch, "entitysearch", "", {
    PageRequested,
    PageSize,
    Filters,
    EntityName,
  });
}

export function Update(
  dispatch,
  successMessage,
  { Id, Name, Attributes },
  entityName,
  methodName
) {
  const method = methodName
    ? methodName
    : GetUpdateMethod(entityName);
  ClearCache(entityName);
  // create or update the entity
  return Post(
    dispatch,
    "Update",
    successMessage,
    {
      Id,
      Name,
      Attributes,
    },
    method
  );
}

export function SetRecordState(
  dispatch,
  { Ids, EntityName, StateCode }
) {
  ClearCache(EntityName);
  // update the status for the given records in the entity
  return Post(
    dispatch,
    "SetRecordState",
    StateCode === 0
      ? "Records successfully activated"
      : "Records successfully deactivated",
    {
      Ids,
      EntityName,
      StateCode,
    }
  );
}

function ClearCache(entityName) {
  // clear the cache for the entity and its active and inactive records
  sessionStorage.removeItem(entityName);
  sessionStorage.removeItem(entityName + "-Active");
  sessionStorage.removeItem(entityName + "-Inactive");

  // clear the cache for the lookups
  const entityLookups = lookups[entityName];
  if (entityLookups) {
    for (const [key, lookup] of Object.entries(entityLookups)) {
      console.log(
        "Removing lookup from session storage.",
        `${key}: ${lookup.name}`
      );
      sessionStorage.removeItem(lookup.name);
    }
  }
}

function GetCreateMethod(entityName) {
  switch (entityName) {
    case "account":
      return "ticketing/createaccount";
    case "campaign":
      return "ticketing/createcampaign";
    case "contact":
      return "ticketing/createcontact";
    case "customeraddress":
      return "ticketing/createcustomeraddress";
    case "g4_answergroup":
      return "ticketing/createanswergroup";
    case "g4_answergroupoption":
      return "ticketing/createanswergroupoption";
    case "g4_answeroption":
      return "ticketing/createansweroption";
    case "g4_answeroptioncategory":
      return "ticketing/createansweroptioncategory";
    case "g4_city":
      return "ticketing/createcity";
    case "g4_country":
      return "ticketing/createcountry";
    case "g4_group":
      return "ticketing/creategroup";
    case "g4_language":
      return "ticketing/createlanguage";
    case "g4_question":
      return "ticketing/createquestion";
    case "g4_region":
      return "ticketing/createregion";
    case "g4b_accesscontrolprovider":
      return "ticketing/createaccesscontrolprovider";
    case "g4b_accesspoint":
      return "ticketing/createaccesspoint";
    case "g4b_additionalproduct":
      return "ticketing/createadditionalproduct";
    case "g4b_analysiscategory":
      return "ticketing/createproductcategory";
    case "g4b_area":
      return "ticketing/createarea";
    case "g4b_block":
      return "ticketing/createblock";
    case "g4b_blockseat":
      return "ticketing/createblockseat";
    case "g4b_blockseatclass":
      return "ticketing/createblockseatclass";
    case "g4b_bookableresource":
      return "ticketing/createbookableresource";
    case "g4b_bookingoperator":
      return "ticketing/createbookingoperator";
    case "g4b_bookableresourcecategory":
      return "ticketing/createbookableresourcecategory";
    case "g4b_calendaritem":
      return "ticketing/createcalendaritem";
    case "g4b_channel":
      return "ticketing/createchannel";
    case "g4b_channelpricelist":
      return "ticketing/createchannelpricelist";
    case "g4b_channelquestion":
      return "ticketing/createchannelquestion";
    case "g4b_couponcategory":
      return "ticketing/createcouponcategory";
    case "g4b_couponproduct":
      return "ticketing/createcouponproduct";
    case "g4b_coupontype":
      return "ticketing/createcoupontype";
    case "g4b_crosssellproduct":
      return "ticketing/createcrosssellproduct";
    case "g4b_fixture":
      return "ticketing/createfixture";
    case "g4b_onholdaudit":
      return "ticketing/createonholdaudit";
    case "g4b_onholdreason":
      return "ticketing/createonholdreason";
    case "g4b_operatorgroup":
      return "ticketing/createoperatorgroup";
    case "g4b_paymentbatch":
      return "ticketing/createpaymentbatch";
    case "g4b_paymentmethod":
      return "ticketing/createpaymentmethod";
    case "g4b_pricebreak":
      return "ticketing/createpricebreak";
    case "g4b_pricebreakgroup":
      return "ticketing/createpricebreakgroup";
    case "g4b_printer":
      return "ticketing/createprinter";
    case "g4b_printtransform":
      return "ticketing/createprinttransform";
    case "g4b_productbase":
      return "ticketing/createbookableproduct";
    case "g4b_productcalendar":
      return "ticketing/createproductcalendar";
    case "g4b_productgroup":
      return "ticketing/createpromotionproductgroup";
    case "g4b_productbasemodifier":
      return "ticketing/createproductmodifier";
    case "g4b_promotion":
      return "ticketing/createpromotion";
    case "g4b_promotionexclusiondate":
      return "ticketing/createpromotionexclusiondate";
    case "g4b_promotiontype":
      return "ticketing/createpromotiontype";
    case "g4b_reportingcategory":
      return "ticketing/createreportingcategory";
    case "g4b_salesplan":
      return "ticketing/createsalesplan";
    case "g4b_seatclass":
      return "ticketing/createseatclass";
    case "g4b_series":
      return "ticketing/createseries";
    case "g4b_taxcode":
      return "ticketing/createtaxcode";
    case "g4b_taxcomponent":
      return "ticketing/createtaxcomponent";
    case "g4b_taxrate":
      return "ticketing/createtaxrate";
    case "g4b_terminal":
      return "ticketing/createterminal";
    case "g4b_tillgroup":
      return "ticketing/createtillgroup";
    case "g4b_variantpricelist":
      return "ticketing/createvariantpricelist";
    case "g4b_varianttype":
      return "ticketing/createvarianttype";
    case "g4b_varianttypecategory":
      return "ticketing/createvarianttypecategory";
    case "g4b_vehicleregistrationnumber":
      return "ticketing/createvehicleregistrationnumber";
    case "g4b_venue":
      return "ticketing/createvenue";
    case "g4b_venueconfiguration":
      return "ticketing/createvenueconfiguration";
    case "g4b_venueconfigurationblock":
      return "ticketing/createvenueconfigurationblock";
    case "g4b_widgetgroup":
      return "ticketing/createwidgetgroup";
    case "g4b_zone":
      return "ticketing/createzone";
    case "g4c_communication":
      return "email-marketing/create-communication";
    case "g4c_communicationaudience":
      return "email-marketing/create-communication-audience";
    case "g4c_communicationoptions":
      return "email-marketing/createcommunicationoptions";
    case "g4c_communicationsavedlayout":
      return "email-marketing/savecommunicationdesignaslayout";
    case "g4c_subscriptiongroup":
      return "ticketing/createsubscriptiongroup";
    case "g4c_triggerflow":
      return "email-marketing/create-trigger-flow";
    case "g4d_charity":
      return "ticketing/createcharity";
    case "g4d_donationbatchheader":
      return "ticketing/create-donation-batch-header";
    case "g4d_fund":
      return "ticketing/createfund";
    case "g4m_membershippaymentplan":
      return "ticketing/createmembershippaymentplan";
    case "g4m_membershippaymentplanschedule":
      return "ticketing/createmembershippaymentplanschedule";
    case "list":
      return "ticketing/createmarketinglist";
    default:
      throw new Error("No create method found for entity");
  }
}

function GetUpdateMethod(entityName) {
  switch (entityName) {
    case "account":
      return "ticketing/updateaccount";
    case "campaign":
      return "ticketing/updatecampaign";
    case "contact":
      return "ticketing/updatecontact";
    case "customeraddress":
      return "ticketing/updatecustomeraddress";
    case "g4_answergroup":
      return "ticketing/updateanswergroup";
    case "g4_answergroupoption":
      return "ticketing/updateanswergroupoption";
    case "g4_answeroption":
      return "ticketing/updateansweroption";
    case "g4_answeroptioncategory":
      return "ticketing/updateansweroptioncategory";
    case "g4_city":
      return "ticketing/updatecity";
    case "g4_country":
      return "ticketing/updatecountry";
    case "g4_group":
      return "ticketing/updategroup";
    case "g4_language":
      return "ticketing/updatelanguage";
    case "g4_question":
      return "ticketing/updatequestion";
    case "g4_region":
      return "ticketing/updateregion";
    case "g4a_accesscontrolstatus":
      return "ticketing/updateaccesscontrolstatus";
    case "g4b_accesscontrolprovider":
      return "ticketing/updateaccesscontrolprovider";
    case "g4b_accesspoint":
      return "ticketing/updateaccesspoint";
    case "g4b_additionalproduct":
      return "ticketing/updateadditionalproduct";
    case "g4b_analysiscategory":
      return "ticketing/updateproductcategory";
    case "g4b_area":
      return "ticketing/updatearea";
    case "g4b_block":
      return "ticketing/updateblock";
    case "g4b_blockseat":
      return "ticketing/updateblockseat";
    case "g4b_blockseatclass":
      return "ticketing/updateblockseatclass";
    case "g4b_bookableresource":
      return "ticketing/updatebookableresource";
    case "g4b_bookingoperator":
      return "ticketing/updatebookingoperator";
    case "g4b_bookingpayment":
      return "ticketing/approve-booking-payment";
    case "g4b_bookableresourcecategory":
      return "ticketing/updatebookableresourcecategory";
    case "g4b_calendaritem":
      return "ticketing/updatecalendaritem";
    case "g4b_channel":
      return "ticketing/updatechannel";
    case "g4b_channelpricelist":
      return "ticketing/updatechannelpricelist";
    case "g4b_channelquestion":
      return "ticketing/updatechannelquestion";
    case "g4b_couponcategory":
      return "ticketing/updatecouponcategory";
    case "g4b_couponproduct":
      return "ticketing/updatecouponproduct";
    case "g4b_coupontype":
      return "ticketing/updatecoupontype";
    case "g4b_crosssellproduct":
      return "ticketing/updatecrosssellproduct";
    case "g4b_fixture":
      return "ticketing/updatefixture";
    case "g4b_onholdaudit":
      return "ticketing/updateonholdaudit";
    case "g4b_onholdreason":
      return "ticketing/updateonholdreason";
    case "g4b_operatorgroup":
      return "ticketing/updateoperatorgroup";
    case "g4b_paymentbatch":
      return "ticketing/updatepaymentbatch";
    case "g4b_paymentmethod":
      return "ticketing/updatepaymentmethod";
    case "g4b_pricebreak":
      return "ticketing/updatepricebreak";
    case "g4b_pricebreakgroup":
      return "ticketing/updatepricebreakgroup";
    case "g4b_printer":
      return "ticketing/updateprinter";
    case "g4b_printtransform":
      return "ticketing/updateprinttransform";
    case "g4b_productbase":
      return "ticketing/updatebookableproduct";
    case "g4b_productcalendar":
      return "ticketing/updateproductcalendar";
    case "g4b_productgroup":
      return "ticketing/updatepromotionproductgroup";
    case "g4b_productbasemodifier":
      return "ticketing/updateproductmodifier";
    case "g4b_promotion":
      return "ticketing/updatepromotion";
    case "g4b_promotionexclusiondate":
      return "ticketing/updatepromotionexclusiondate";
    case "g4b_promotiontype":
      return "ticketing/updatepromotiontype";
    case "g4b_reportingcategory":
      return "ticketing/updatereportingcategory";
    case "g4b_salesplan":
      return "ticketing/updatesalesplan";
    case "g4b_seatclass":
      return "ticketing/updateseatclass";
    case "g4b_series":
      return "ticketing/updateseries";
    case "g4b_taxcode":
      return "ticketing/updatetaxcode";
    case "g4b_taxcomponent":
      return "ticketing/updatetaxcomponent";
    case "g4b_taxrate":
      return "ticketing/updatetaxrate";
    case "g4b_telegramqueue":
      return "ticketing/updatetelegramqueue";
    case "g4b_terminal":
      return "ticketing/updateterminal";
    case "g4b_tillgroup":
      return "ticketing/updatetillgroup";
    case "g4b_variantpricelist":
      return "ticketing/updatevariantpricelist";
    case "g4b_varianttype":
      return "ticketing/updatevarianttype";
    case "g4b_varianttypecategory":
      return "ticketing/updatevarianttypecategory";
    case "g4b_vehicleregistrationnumber":
      return "ticketing/updatevehicleregistrationnumber";
    case "g4b_venue":
      return "ticketing/updatevenue";
    case "g4b_venueconfiguration":
      return "ticketing/updatevenueconfiguration";
    case "g4b_venueconfigurationblock":
      return "ticketing/updatevenueconfigurationblock";
    case "g4b_widgetgroup":
      return "ticketing/updatewidgetgroup";
    case "g4b_zone":
      return "ticketing/updatezone";
    case "g4c_communication":
      return "email-marketing/update-communication";
    case "g4c_communicationaudience":
      return "email-marketing/update-communication-audience";
    case "g4c_communicationoptions":
      return "email-marketing/updatecommunicationoptions";
    case "g4c_communicationsavedlayout":
      return "email-marketing/updatesavedcommunicationlayout";
    case "g4c_subscriptiongroup":
      return "ticketing/updatesubscriptiongroup";
    case "g4c_triggerflow":
      return `email-marketing/update-trigger-flow`;
    case "g4d_charity":
      return "ticketing/updatecharity";
    case "g4d_donationbatchheader":
      return "ticketing/update-donation-batch-header";
    case "g4d_fund":
      return "ticketing/updatefund";
    case "g4m_membershippaymentplan":
      return "ticketing/updatemembershippaymentplan";
    case "g4m_membershippaymentplanschedule":
      return "ticketing/updatemembershippaymentplanschedule";
    case "list":
      return "ticketing/updatemarketinglist";
    default:
      throw new Error("No update method found for entity");
  }
}
