import { useEffect, useState } from "react";
import moment from "moment";
import { getEnum } from "../components/form/Helpers";
import { GetEntityRecordById } from "./service";
import {
  BadgeList,
  FormPageHeader,
  FormPageHeaderSimple,
  StatusBadge,
  SubmitFormNavigationButtons,
  SubmitFormNavigationButtonsSimple,
  TextArea,
} from "../components/elements/_Elements";
import { BackgroundStyle } from "./enums";
import CommunicationPageHeader from "../components/form/communication/CommunicationPageHeader";
import AccessControlProviderPageHeader from "../components/form/venue/accessControlProvider/AccessControlProviderPageHeader";
import CommunicationNavigationButtons from "../components/form/communication/CommunicationNavigationButtons";
import CommunicationFlowNavigationButtons from "../components/form/communicationFlow/CommunicationFlowNavigationButtons";
import ContactFormPageHeader from "../components/elements/ContactFormPageHeader";

const currencyFormat = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

const priceRegex = /^\d+(\.\d{1,2})?$/;

const checkDateTimeIsAfterToday = (date) => {
  if (date) {
    return moment(date).isAfter();
  }
  return false;
};

const checkIfSellFixturesAndSeriesSettingOn = (globalState) => {
  return (
    globalState &&
    globalState.config &&
    globalState.config["g4b_sellfixturesandseries"]
  );
};

const checkIfTwoArraysAreTheSame = (array1, array2) => {
  //If both arrays are null, then return true
  if (!array1 && !array2) {
    return true;
  }

  //If at least one of the two arrays are null or if their lengths don't match then return false
  if (!array1 || !array2 || array1.length !== array2.length) {
    return false;
  }

  //If at least one value in each arrays indexes don't match, then return false
  for (var i = 0; i < array1.length; ++i) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  //Otherwise they match
  return true;
};

const convertDateTimeToUTCTimeZoneWhileMaintainingDateTimeValue = (
  date
) => {
  if (date) {
    return moment(date).utc(true);
  }
  return null;
};

const createDateFromToday = function (days, months, years) {
  var date = new Date();
  date.setDate(date.getDate() + days);
  date.setMonth(date.getMonth() + months);
  date.setFullYear(date.getFullYear() + years);
  return date;
};

const createDate = function (days, months, years) {
  var date = new Date();
  date.setDate(days);
  date.setMonth(months);
  date.setFullYear(years);
  return date;
};

const createMarkup = function (markup) {
  return { __html: markup };
};

function disableLinkClicks(event) {
  event.preventDefault();
  event.stopPropagation();
}

const downloadAnnotation = async (id) => {
  const [serviceResponse] = await Promise.all([
    GetEntityRecordById(null, "annotation", id),
  ]);

  if (serviceResponse && serviceResponse.data) {
    const { documentbody, filename, mimetype } =
      serviceResponse.data.Fields;

    // Decode base64 string to binary data
    const binaryData = atob(documentbody);

    // Convert binary data to array buffer
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create blob from array buffer
    const blob = new Blob([uint8Array], { type: mimetype });

    // Create URL for the blob
    const url = URL.createObjectURL(blob);

    // Create link element and trigger download
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    // Remove the link
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
};

//The FormDatePicker component displays the numerical day value for a date with a lowercase d
//Moment displays the numerical day value for a date with a uppercase D whereas lowercase d is for the worded day value.
//So if the field has a dateformat set to dd/MM/yyyy then for a date value of 16/11/2023
//moment would display it as Th/11/2023. This function corrects this into the correct format
function convertDayFormatForMoment(dateFormat) {
  if (dateFormat === "dd/MM/yyyy HH:mm:ss") {
    return "DD/MM/yyyy HH:mm:ss";
  }
  if (dateFormat === "dd/MM/yyyy HH:mm") {
    return "DD/MM/yyyy HH:mm";
  }
  if (dateFormat === "dd/MM/yyyy") {
    return "DD/MM/yyyy";
  }
  return dateFormat;
}

const getAllYears = () => {
  return Array.from(
    { length: 2099 - 1900 + 1 },
    (_, index) => 1900 + index
  ).map((year) => ({ Key: year, Value: year }));
};

const getEntityNameAttribute = (entityName) => {
  switch (entityName) {
    case "annotation":
      return "subject";
    case "account":
    case "campaign":
    case "customeraddress":
      return "name";
    case "contact":
      return "fullname";
    case "g4_answergroup":
    case "g4_answergroupoption":
    case "g4_answeroption":
    case "g4_answeroptioncategory":
    case "g4_city":
    case "g4_configuration":
    case "g4_country":
    case "g4_group":
    case "g4_language":
    case "g4_question":
    case "g4_region":
      return "g4_name";
    case "g4b_booking":
      return "g4b_bookingreference";
    case "g4b_bookingproduct":
      return "g4b_protoproductidname";
    case "g4c_communication":
    case "g4c_communicationaudience":
    case "g4c_communicationoptions":
    case "g4c_communicationsavedlayout":
    case "g4c_subscriptiongroup":
    case "g4c_template":
    case "g4c_triggerflow":
      return "g4c_name";
    case "g4d_fund":
    case "g4d_charity":
      return "g4d_name";
    case "g4gdpr_contactaudit":
      return "g4gdpr_name";
    case "g4m_membership":
    case "g4m_membershipaccount":
    case "g4m_membershippaymentplan":
    case "g4m_membershippaymentplanschedule":
      return "g4m_name";
    case "list":
      return "listname";
    default:
      return "g4b_name";
  }
};

const getFieldDataType = (field) => {
  if (field === null || field === undefined) {
    return "";
  }

  switch (typeof field) {
    case "number":
      return "number";
    case "boolean":
      return "boolean";
    case "string":
      return "string";
    case "object":
      return Array.isArray(field) ? "array" : "object";
    default:
      return moment(field, moment.ISO_8601, true).isValid()
        ? "datetime"
        : "";
  }
};

const getFutureYears = () => {
  return Array.from(
    { length: 2099 - new Date().getFullYear() + 1 },
    (_, index) => new Date().getFullYear() + index
  ).map((year) => ({ Key: year, Value: year }));
};

const getImageBase64Src = (mimeType, base64String) => {
  return `data:${mimeType};base64,${base64String}`;
};

const getLookupData = (lookupName, state) => {
  const lookupData =
    state && state.lookupOptions
      ? state.lookupOptions.find(
          (options) => options.name === lookupName
        )
      : null;

  return lookupData && lookupData.data && lookupData.data.length > 0
    ? lookupData.data
    : null;
};

const getPastYears = () => {
  return Array.from(
    { length: new Date().getFullYear() - 1900 + 1 },
    (_, index) => 1900 + index
  ).map((year) => ({ Key: year, Value: year }));
};

function getOrdinalSuffix(n) {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

function displayFieldValue(value, type = "", field, record, state) {
  if (field && field.displayFieldValue) {
    return field.displayFieldValue(value, record);
  }

  if (value === null || value === undefined) {
    return "";
  }

  const lookupData =
    state && state.lookupOptions && field && field.lookup
      ? state.lookupOptions.find(
          (options) => options.name === field.lookup.name
        )
      : null;

  const fieldType = type !== "" ? type : getFieldDataType(value);
  switch (fieldType) {
    case "number":
      return value.toLocaleString("en-GB", {
        useGrouping: "true",
        minimumFractionDigits: "2",
        maximumFractionDigits: "4",
      });
    case "money":
      return currencyFormat.format(value);
    case "boolean":
      return displayBoolean(value, "No");
    case "string":
      return value;
    case "object":
    case "picklist":
      //If record isn't in the form of {Name, Id, Fields} and is instead a value, then check for whether
      //a enum was provided and match the value to the enum value and return the enum key name
      return value !== undefined && String(value)
        ? value.Name !== undefined && String(value.Name)
          ? String(value.Name)
          : field && field.enum
          ? String(
              Object.keys(field.enum).some(
                (key) => String(field.enum[key]) === String(value)
              )
                ? Object.keys(field.enum).find(
                    (key) => String(field.enum[key]) === String(value)
                  )
                : ""
            )
          : ""
        : "";
    case "array":
      return value && value.length > 0 ? (
        <BadgeList
          backgroundStyle={BackgroundStyle.Info}
          badgeClassName={"me-2 mb-2"}
          items={
            value &&
            lookupData &&
            lookupData.data &&
            lookupData.data.length > 0 //if the value is an array of Guids then convert them into their named text form by looking in the lookup data
              ? value.map((v) => {
                  var lookupDataEntry = lookupData.data.find(
                    (l) => l.Key === v
                  );
                  if (lookupDataEntry && lookupDataEntry.Value) {
                    return { text: lookupDataEntry.Value };
                  } else {
                    return { text: "" };
                  }
                })
              : [{ text: value }]
          }
        />
      ) : (
        ""
      );
    case "statusbadge":
      return value !== undefined && field && field.enum ? (
        <StatusBadge enumList={field.enum} value={value} />
      ) : (
        ""
      );
    case "datetime":
      return field && field.dateFormat && value
        ? moment(value).format(
            convertDayFormatForMoment(field.dateFormat)
          )
        : value
        ? displayLocalDate(value)
        : "";
    default:
      return "";
  }
}

function displayLocalDate(date) {
  if (date !== 0 && date !== undefined) {
    return new Date(date).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  } else {
    return "";
  }
}

function displayBoolean(bool, defaultBool) {
  if (bool !== undefined) {
    return bool === true ? "Yes" : "No";
  } else {
    return defaultBool;
  }
}

function formatCurrency(value) {
  return currencyFormat.format(value);
}

function getHeaderComponent(entityName, onSubmitSubForm) {
  switch (entityName) {
    case "g4c_communication":
      return CommunicationPageHeader;
    case "g4c_triggerflow":
      return FormPageHeader;
    case "contact":
      return ContactFormPageHeader;
    case "account":
    case "g4b_booking":
    case "g4b_companydetails":
    case "g4b_coupon":
    case "g4b_seatallocation":
    case "g4b_telegramqueue":
    case "g4b_ticket":
    case "g4b_voucher":
    case "g4c_communicationoptions":
    case "g4m_membershipaccount":
      return FormPageHeaderSimple;
    case "g4b_accesscontrolprovider":
      return AccessControlProviderPageHeader;
    default:
      return onSubmitSubForm ? FormPageHeaderSimple : FormPageHeader;
  }
}

function getNavigationButtons(entityName) {
  switch (entityName) {
    case "g4c_communication":
      return CommunicationNavigationButtons;
    case "g4c_triggerflow":
      return CommunicationFlowNavigationButtons;
    case "g4b_companydetails":
    case "g4c_communicationoptions":
      return SubmitFormNavigationButtonsSimple;
    default:
      return SubmitFormNavigationButtons;
  }
}

const isSameOrBeforeDate = (date, dateToCompare) => {
  // if either value is not set then return true
  if (!date || !dateToCompare) {
    return true;
  }
  // check both are date objects
  if (typeof date === "string") date = new Date(date);
  if (typeof dateToCompare === "string")
    dateToCompare = new Date(dateToCompare);
  // compare the dates
  return date <= dateToCompare;
};

const isSameOrLaterDate = (date, dateToCompare) => {
  // if either value is not set then return true
  if (!date || !dateToCompare) {
    return true;
  }
  // check both are date objects
  if (typeof date === "string") date = new Date(date);
  if (typeof dateToCompare === "string")
    dateToCompare = new Date(dateToCompare);
  // compare the dates
  return date >= dateToCompare;
};

const isValidEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

function getEntityNameForRegarding(regardingType) {
  switch (String(regardingType)) {
    case "1":
      return "g4b_booking";
    case "2":
      return "g4b_bookingpayment";
    case "3":
      return "contact";
    case "4":
    case "5":
      return "g4m_membership";
    case "6":
      return "account";
    case "7":
      return "g4b_coupon";
    case "8":
      return "g4b_voucher";
    default:
      return "";
  }
}

function getEnumKey(type, value) {
  return Object.keys(type).find(
    (key) => type[key] === parseInt(value, 10)
  );
}

function getReadOnlyValue(element, state, values, globalState) {
  const { dateFormat, getReadOnlyValue, textAreaHeight, type, name } =
    element;
  if (getReadOnlyValue) {
    return getReadOnlyValue(state);
  }

  const value = state[name];
  const emptyValue = "--";

  switch (type) {
    case "bit":
      return value ? "Yes" : "No";
    case "picklist":
      const entries = Object.entries(
        getEnum(element, state, values, globalState)
      );
      // try to match as an integer
      let match = entries.find(([_, val]) => val === parseInt(value));
      if (match) {
        return match[0];
      }
      // else try to match on just the value
      match = entries.find(([_, val]) => val === value);
      if (match) {
        return match[0];
      }
      return emptyValue;
    case "lookup":
      return value ? state[name + "name"] : emptyValue;
    case "datetime":
      return dateFormat && value
        ? moment(value).format(convertDayFormatForMoment(dateFormat))
        : value
        ? displayLocalDate(value)
        : emptyValue;
    case "richtexteditor":
      return <p dangerouslySetInnerHTML={createMarkup(value)} />;
    case "money":
      return value ? currencyFormat.format(value) : emptyValue;
    case "textarea":
      return (
        <TextArea
          disabled={true}
          height={textAreaHeight ? textAreaHeight : "60px"}
          value={value}
        />
      );
    default:
      return value || emptyValue;
  }
}

function isRegardingDifferentToListType(listType, regardingType) {
  const targetAtEntityNumber = String(listType) === "1" ? 6 : 3; //6 for account, 3 for contact
  return String(regardingType) !== String(targetAtEntityNumber);
}

const getUpdatedLookupOptionsValues = (
  newData,
  lookup,
  lookupOptions
) => {
  // clear the cache for the lookup options
  lookupOptions
    .filter((l) => l.name === lookup.name)
    .forEach((l) => {
      sessionStorage.removeItem(l.name);
    });

  // update the lookup options
  return lookupOptions.map((l) =>
    l.name === lookup.name
      ? {
          name: l.name,
          data: [
            ...l.data.filter((data) => data.Key !== newData.Key),
            newData,
          ].sort((a, b) => {
            if (a.Value === null && b.Value === null) {
              return 0;
            } else if (a.Value === null && b.Value !== null) {
              return -1;
            } else if (a.Value !== null && b.Value === null) {
              return 1;
            } else {
              return a.Value.localeCompare(b.Value);
            }
          }),
        }
      : l
  );
};

const useClickOutside = (ref, handler) => {
  useEffect(() => {
    let startedInside = false;
    let startedWhenMounted = false;

    const listener = (event) => {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside || !startedWhenMounted) return;
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return;

      handler(event);
    };

    const validateEventStart = (event) => {
      startedWhenMounted = ref.current;
      startedInside =
        ref.current && ref.current.contains(event.target);
    };

    document.addEventListener("mousedown", validateEventStart);
    document.addEventListener("touchstart", validateEventStart);
    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("mousedown", validateEventStart);
      document.removeEventListener("touchstart", validateEventStart);
      document.removeEventListener("click", listener);
    };
  }, [ref, handler]);
};

function useHasScrollbar() {
  const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(() => {
    // Enable scrollbars on the body
    document.body.style.overflow = "scroll";

    // Calculate scrollbar width
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;

    // Check if scrollbar width is greater than 0
    setHasScrollbar(scrollbarWidth > 0);

    // Reset overflow style
    document.body.style.overflow = "";

    // Cleanup function
    return () => {
      document.body.style.overflow = "";
    };
  }, []); // Run only once on component mount

  return hasScrollbar;
}

function useWindowResize(event) {
  const throttleTime = 200;

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    let timeoutId;

    // Handler to update window size when the window is resized
    function handleResize() {
      if (!timeoutId) {
        timeoutId = setTimeout(() => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
          timeoutId = null;
        }, throttleTime);
      }
    }

    // Add event listener to "resize" event
    window.addEventListener("resize", handleResize);

    // Add event listener to custom event, if provided
    if (event) {
      window.addEventListener("resize", event);
    }

    // Clean up the event listener and timeout when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);

      if (event) {
        window.removeEventListener("resize", event);
      }

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [event, throttleTime]);

  return windowSize;
}

export {
  checkDateTimeIsAfterToday,
  checkIfSellFixturesAndSeriesSettingOn,
  checkIfTwoArraysAreTheSame,
  convertDateTimeToUTCTimeZoneWhileMaintainingDateTimeValue,
  convertDayFormatForMoment,
  createDate,
  createDateFromToday,
  createMarkup,
  disableLinkClicks,
  displayFieldValue,
  displayLocalDate,
  downloadAnnotation,
  formatCurrency,
  getAllYears,
  getEntityNameAttribute,
  getEntityNameForRegarding,
  getEnumKey,
  getFutureYears,
  getImageBase64Src,
  getHeaderComponent,
  getLookupData,
  getNavigationButtons,
  getOrdinalSuffix,
  getPastYears,
  getReadOnlyValue,
  getUpdatedLookupOptionsValues,
  isRegardingDifferentToListType,
  isSameOrBeforeDate,
  isSameOrLaterDate,
  isValidEmail,
  priceRegex,
  useClickOutside,
  useHasScrollbar,
  useWindowResize,
};
